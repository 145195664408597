import { Col, Dropdown, Menu, Row } from 'antd'
import { H3Bold, TextRegular } from '../shared/typography'
import React, { Suspense, useContext, useEffect, useState } from 'react'

import { CartButton } from './cart'
import { GiWineBottle } from '@react-icons/all-files/gi/GiWineBottle'
import { FaTruck } from '@react-icons/all-files/fa/FaTruck'
import { ICollectionLink } from './layout'
import { StoreContext } from '../../context/store-context'
import { TextLinkTextSmall } from '../shared/button'
import { Toast } from '../shared/toast'
import { Link, navigate } from 'gatsby'
import { regexSlug } from '../shared/utils'
import styled from '@emotion/styled'
import { theme } from '../shared/theme'
import SearchComponent from '../search/search-component'
const Burger = React.lazy(() => import('./burger'))

const HeaderContainer = styled.div`
  top: 0px;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  background: ${theme.colors.brandLight.darkred};
  z-index: 999;

  @media (max-width: 760px) {
    position: fixed;
    box-shadow: ${theme.effects.shadow01};
  }
`

const InnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  height: 100%;
  background: ${theme.colors.bgLight.white};
  margin-top: 40px;
  @media (max-width: 900px) {
    margin-top: 0;
  }
`

const PreNavCol = styled(Col)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  background: ${theme.colors.greyLight.grey15};
  padding: 8px;
  align-items: center;
  align-content: center;
`

const HeaderContent = styled.div`
  display: flex;
  height: 110px;
  width: 100%;
  z-index: 1;
`

const Logo = styled.img`
  width: 230px;
  align-self: center;
  margin-right: 2rem;
  height: auto;
  border-radius: 5px;
  background: white;
  cursor: pointer;

  @media (max-width: 1299px) {
    width: 250px;
  }

  @media (max-width: 495px) {
    width: 150px;
  }
`

const Icons = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.1s ease-in;

  &:hover {
    transform: scale(1.2);
  }
  `

const MenuItemStyled = styled(Menu.Item)`
  width: 100%;
`

const DropDownBtn = styled.button`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.txtLight.black};
  margin-block-start: 1em;
  margin-block-end: 1em;
  display: block;
  align-self: center;
  padding: 0 1rem;
  border: none;
  cursor: pointer;
  background: transparent;
  &:hover {
    color: ${theme.colors.greyLight.grey55};
    background: transparent;
  }
`

const StyledMainCat = styled.a`
  cursor: pointer;
  transition: all 0.2s ease-in;
  font-size: 18px;
  padding: 0.75rem 0.5rem;
  margin: 0 -0.75rem;
  display: block;
  color: ${theme.colors.txtLight.black};
  width: 100%;
  white-space: pre-wrap;
  &.active,
  &:hover {
    font-weight: bold;
    background: ${theme.colors.greyLight.grey5};
  }

  @media(max-width: 1000px) {
    font-size: 16px;
    padding: 0.5rem 0;
  }

  strong {
    color: #1890ff;
  }
`
const StyledLinkNav = styled(Link)`
  font-size: 16px;
  color: ${theme.colors.txtLight.black};
  padding: 0.75rem 0.5rem;

`

type Props = {
  winetype: ICollectionLink[]
  producent: ICollectionLink[]
  country: ICollectionLink[]
  areas: ICollectionLink[]
  width: number
}

const HeaderComponent: React.FC<Props> = ({ winetype, producent, country, areas, width }: Props) => {
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext)
  const [showBurger, setShowBurger] = useState<boolean>(false)
  const items: any = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total: number, item: any) => {
    return total + item.quantity
  }, 0)

  const menu = (
    <Menu mode="vertical" style={{ marginTop: '1rem' }}>
      <Row style={{ padding: '12px', background: 'white' }} justify={'start'} wrap align="top">
        <Col md={8} lg={6}>
          <Row >
            <H3Bold style={{ padding: '0 0.5rem', borderBottom: `2px solid ${theme.colors.brandLight.darkred}`, width: '50%' }}>Producenter</H3Bold>
            {producent.slice(0, 8).map((menuNode: ICollectionLink) => (
              <MenuItemStyled key={menuNode.title} style={{ background: 'white' }}>
                <StyledMainCat href={`/producenter/${regexSlug(menuNode.title)}/`}>{menuNode.title}</StyledMainCat>
              </MenuItemStyled>
            ))}
            <MenuItemStyled key="alle producenter" style={{ background: 'white' }}>
              <StyledMainCat href={`/producenter/`}>
                <strong>{'Se alle producenter'}</strong>
              </StyledMainCat>
            </MenuItemStyled>
          </Row>
        </Col>
        <Col md={5} lg={6}>
          <Row>
            <H3Bold style={{ padding: '0 0.5rem', borderBottom: `2px solid ${theme.colors.brandLight.darkred}`, width: '50%' }}>Vine</H3Bold>
            {winetype.map((menuNode: ICollectionLink) => (
              <MenuItemStyled key={menuNode.title} style={{ background: 'white' }}>
                <StyledMainCat href={`/vine/${regexSlug(menuNode.title)}/`}>{menuNode.title}</StyledMainCat>
              </MenuItemStyled>
            ))}
            <MenuItemStyled key="øke" style={{ background: 'white' }}>
              <StyledMainCat href={'/vine/oekologisk/'}>{'Økologiske vine'}</StyledMainCat>
            </MenuItemStyled>
            <MenuItemStyled key="naturvin" style={{ background: 'white' }}>
              <StyledMainCat href={'/vine/naturvin/'}>{'Naturvine'}</StyledMainCat>
            </MenuItemStyled>
            <MenuItemStyled key="smagekasser" style={{ background: 'white' }}>
              <StyledMainCat href={'/vine/smagekasser/'}>{'Smagekasser'}</StyledMainCat>
            </MenuItemStyled>
            <MenuItemStyled key="tilbud" style={{ background: 'white' }}>
              <StyledMainCat href={'/vine/tilbud/'} style={{ display: 'flex', alignContent: 'center' }}>
                {'Vin tilbud '}
              </StyledMainCat>
            </MenuItemStyled>
            <MenuItemStyled key="alle vine" style={{ background: 'white' }}>
              <StyledMainCat href={`/vine`}>
                <strong>{'Se alle vine'}</strong>
              </StyledMainCat>
            </MenuItemStyled>
          </Row>
        </Col>
        <Col md={5} lg={6}>
          <Row>
            <H3Bold style={{ padding: '0 0.5rem', borderBottom: `2px solid ${theme.colors.brandLight.darkred}`, width: '50%' }}>Lande</H3Bold>
            {country.slice(0, 8).map((menuNode: ICollectionLink) => (
              <MenuItemStyled key={menuNode.title} style={{ background: 'white' }}>
                <StyledMainCat href={`/vine/${regexSlug(menuNode.title)}/`}>{menuNode.title}</StyledMainCat>
              </MenuItemStyled>
            ))}
          </Row>
        </Col>
        <Col md={5} lg={6}>
          <Row>
            <H3Bold style={{ padding: '0 0.5rem', borderBottom: `2px solid ${theme.colors.brandLight.darkred}`, width: '49%' }}>Vindistrikter</H3Bold>
            {areas.slice(0, 8).map((menuNode: ICollectionLink) => (
              <MenuItemStyled key={menuNode.title} style={{ background: 'white' }}>
                <StyledMainCat href={`/vine/${regexSlug(menuNode.title)}/`}>{menuNode.title}</StyledMainCat>
              </MenuItemStyled>
            ))}
            <MenuItemStyled key="Alle vindistrikter" style={{ background: 'white' }}>
              <StyledMainCat href={`/distrikter/`}>
                <strong>{'Se alle vindistrikter / områder'}</strong>
              </StyledMainCat>
            </MenuItemStyled>
          </Row>
        </Col>
      </Row>
    </Menu>
  )

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <HeaderContainer>
      {width < 1400 && <Suspense fallback={<div></div>}>
        <Burger isOpen={showBurger} onClose={() => setShowBurger(!showBurger)} /></Suspense>}
      {width > 900 && <Row style={{ width: '100%', position: 'fixed', zIndex: '9999' }}>
        <PreNavCol span={8}><FaTruck size={24} style={{ marginRight: '8px' }} /><TextRegular style={{ width: 'fit-content', margin: 0, color: 'black', fontSize: '14px' }}>Fragt 59,- | Fri fragt 1.000,-</TextRegular></PreNavCol>
        <PreNavCol span={8}><GiWineBottle size={24} style={{ marginRight: '8px' }} /><TextRegular onClick={() => navigate("/showroom")} style={{ width: 'fit-content', margin: 0, color: 'black', fontSize: '14px', cursor: "pointer" }}>Showroom åbent hver fredag</TextRegular></PreNavCol>
        <PreNavCol span={8}>
          <div
            className="trustpilot-widget"
            data-locale="da-DK"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="61899e7d1f7e0196fd74c6c8"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
            data-stars="1,2,3,4,5"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-allow-robots="true"
          >
          </div>
        </PreNavCol>
      </Row>}
      <InnerHeader>
        <HeaderContent>
          {width < 1400 && <Icons src={showBurger ? '/icons/close.svg' : '/icons/menu.svg'} onClick={() => setShowBurger(!showBurger)} alt="Menu mansted wine" />}
          <Logo
            src={width < 1300 ? '/logo/logo.jpg' : '/logo/mansted-wine-logo.jpg'}
            onClick={() => navigate('/')}
            alt="Mansted wine logo"
            width={width < 100 ? 400 : 350}
            height={130}
          />
        </HeaderContent>
            <SearchComponent />
        <HeaderContent style={{ justifyContent: 'flex-end' }}>
          <CartButton width={width} quantity={quantity} price={checkout.totalPriceV2?.amount || null} />{' '}
          <Toast show={loading || didJustAddToCart}>
            {!didJustAddToCart ? (
              'Opdaterer…'
              ) : (
                <>
                Tilføjet til kurv{' '}
                <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z" fill="#fff" />
                  <path d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z" fill="#fff" />
                  <path d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z" fill="#fff" />
                </svg>
              </>
            )}
          </Toast>
        </HeaderContent>
      </InnerHeader>
        <Row style={{background: 'white', width: '100%'}}>
      <div className="flex ml-4">
        <ul style={{ listStyle: 'none', display: 'flex' }}>
          <li>
            <StyledLinkNav to="/vine">Alle Vine</StyledLinkNav>
          </li>
          {/* <li >
                  <StyledLinkNav to="">Nyheder</StyledLinkNav>
                </li> */}
          <li>
            <StyledLinkNav to="/vine/tilbud" style={{color: 'red'}}>Tilbud</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/vine/roedvin">Rødvin</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/vine/hvidvin">Hvidvin</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/vine/champagne/">Champagne</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/vine/smagekasser/">Smagekasser</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/tilbehoer">Tilbehør</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/producenter">Producenter</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/smagninger">Vin Smagninger</StyledLinkNav>
          </li>
          <li>
            <StyledLinkNav to="/kontakt">Kontakt</StyledLinkNav>
          </li>
        </ul>
      </div>
        </Row>
    </HeaderContainer>
  )
}
export default HeaderComponent
